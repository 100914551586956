import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/Alert-dialog/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/CardListing/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/Clock/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/Collapsible/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/Command/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/Dialog/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/Dropdown-menu/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/ExpirationDelay/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/FeedbackForm/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/Footer/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/Form/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/Label/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/LayoutClient/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/Logo/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/ModeToogle/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/Navbar/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/Popover/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/SidebarMenu/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/TestComponent/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-components-react-nextjs-bundle/dist/components/Tooltip/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default","useTenant"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/@phpcreation/frontend-config-react-nextjs-bundle/dist/contexts/TenantContext.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/components/AuthClient/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/components/ui/ThemeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TaskManagerContextProvider"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/containers/TaskManager/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/contexts/CSRFContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["KVSContextProvider"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/contexts/KVSContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RolesContextProvider"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/contexts/RolesContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TimesheetContextProvider"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/contexts/TimesheetContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserContextProvider"] */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/contexts/UserContext.tsx");
